import { configureStore } from '@reduxjs/toolkit';
import cryptoReducer from '@features/crypto/cryptoSlice';
import accountReducer from '@features/accountReducer';
import dashboardReducer from '@features/dashboardReducer';

import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';

const store = configureStore({
    reducer: {
        crypto: cryptoReducer,
        account: accountReducer,
        dashboard: dashboardReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
})


const { dispatch } = store;
const useDispatch = () => useAppDispatch();
const useSelector = useAppSelector;
export { store, dispatch, useSelector, useDispatch };

