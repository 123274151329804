// styling
import styles from './style.module.scss';

// components
import { NavLink } from 'react-router-dom';

// utils
import { memo } from 'react';

// hooks
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useThemeProvider } from '@contexts/themeContext';
import useAuthRoute from '@hooks/useAuthRoute';
import { useModal } from '@contexts/modalContext';

const BottomNav = () => {
    const ref = useRef(null);
    const location = useLocation();
    const { theme } = useThemeProvider();
    const isActive = (path) => location.pathname === path;
    const isAuthRoute = useAuthRoute();
    const { modal, handleOpen } = useModal();

    useEffect(() => {
        const height = ref.current.offsetHeight;
        window.document.documentElement.style.setProperty('--bottom-nav-height', `${height}px`);

        return () => {
            window.document.documentElement.style.removeProperty('--bottom-nav-height');
        }
    }, []);

    return (
        <>
            {
                !isAuthRoute ? (
                    <div className={`${styles.container} ${theme === 'dark' ? styles.dark : ''}`} ref={ref}>
                        {/* {
                            
                            
                            LINKS.map(link => {
                                const { icon, path, desktop, mobile } = link;
                                const isSm = icon === 'collections';

                                return (
                                    <NavLink className={`nav-link ${isActive(path) ? 'active' : ''}`} to={path} key={path}>
                                        <span className="wrapper">
                                            <i className={`icon icon-${icon} ${isSm ? 'sm' : ''}`} />
                                            <i className={`icon icon-fill ${isActive(path) ? 'active' : ''} icon-${icon}${icon !== 'trade' ? '-active' : ''} ${isSm ? 'sm' : ''}`} />
                                        </span>
                                        {mobile ? mobile : desktop}
                                    </NavLink>
                                )
                            })
                        } */}
                        <NavLink className={`nav-link ${isActive('/') ? 'active' : ''}`} to='/' key='/'>
                            <span className="wrapper">
                                <i className={`icon icon-dashboard`} />
                                <i className={`icon icon-fill ${isActive('/') ? 'active' : ''} icon-dashboard-active`} />
                            </span>
                            Home
                        </NavLink>
                        <NavLink className={`nav-link ${isActive('/wallet') ? 'active' : ''}`} to='/wallet' key='/wallet'>
                            <span className="wrapper">
                                <i className={`icon icon-wallet`} />
                                <i className={`icon icon-fill ${isActive('/wallet') ? 'active' : ''} icon-wallet-active`} />
                            </span>
                            Wallet
                        </NavLink>
                        <button className={`nav-link ${modal === 'refer' ? 'active' : ''}`} onClick={() => handleOpen('refer')}
                        >
                            <span className="wrapper">
                                <i className={`icon icon-actions ${modal !== 'refer' ? 'active' : ''}`} />
                                <i className={`icon icon-fill ${modal === 'refer' ? 'active' : ''} icon-actions-active`} />
                            </span>
                            Referrals
                        </button>
                        <button className={`nav-link nav-link--sidebar ${styles.list_item} ${modal === 'account' ? 'active' : ''}`}
                            onClick={() => handleOpen('account')}>
                            <span className="wrapper">
                                <i className={`icon icon-nft ${styles.sm} ${modal !== 'account' ? 'active' : ''}`} />
                                <i className={`icon icon-nft icon-fill ${styles.sm} ${modal === 'account' ? 'active' : ''}`} />
                            </span>
                            Account
                        </button>
                    </div>
                ) : null
            }

        </>
    )
}

export default memo(BottomNav);