// components
import React, { useEffect, useState } from 'react';
import axios from '@utils/axios';

import Fade from '@mui/material/Fade';

// styling
import styles from './style.module.scss';

// assets
import loader from '@assets/loader.svg';
import { useAuth } from 'AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

const Loader = ({ visible }) => {
  const [timeout, setTimeout] = useState(400);
  const navigate = useNavigate();
  const { setauth } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const validateToken = async () => {
      try {
        const token = localStorage.getItem('sessionToken');
        if (!token) {
          setTimeout(400)
        } else {
          axios.defaults.headers.common.Authorization = `Bearer ${token}`;
          const response = await axios.post('/api/refresh');

          if (response.data.success == "1") {
            setTimeout(100)
            setauth(response.data)
          }
        }

      } catch (err) {
        localStorage.removeItem('token');
        setTimeout(400)
      } finally {
        setTimeout(400)
      }
    };

    if (location.pathname != '/sign-up') {
      validateToken();
    }

  }, [navigate]);


  return (
    <div className="app">
      <div className="app_container">
        <Fade in={visible} timeout={400}>
          <div className={styles.container}>
            <img className={styles.animation} src={loader} alt="loader" />
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default Loader