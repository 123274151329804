import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dashboard: null,
    products: [],
    error: null,
};

const dashboardReducer = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    storeData:(state, action) => {
        state.dashboard = action.payload
    },
    storeProducts:(state, action) => {
      state.products = action.payload
    },
    hasError(state, action) {
      state.error = action.payload;
  },
  },
})


export const {storeData, storeProducts} = dashboardReducer.actions

export default dashboardReducer.reducer