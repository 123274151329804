// GA
import ReactGA from 'react-ga4';

import { AuthProvider } from './AuthContext';
import AuthGuard from './AuthGuard';
// utils
import {lazy, Suspense} from 'react';

// styles
import ThemeStyles from '@styles/theme';
import './style.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-grid-layout/css/styles.css';

// contexts
import {SidebarProvider} from '@contexts/sidebarContext';
import {ThemeProvider} from 'styled-components';
import {ModalProvider} from '@contexts/modalContext';

// hooks
import {useThemeProvider} from '@contexts/themeContext';
import {useEffect, useRef} from 'react';
import {useWindowSize} from 'react-use';

// components
import {Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import Loader from '@components/Loader';
import Sidebar from '@components/Sidebar';
import BottomNav from '@components/BottomNav';
const Dashboard = lazy(() => import('@pages/Dashboard'));
const Trade = lazy(() => import('@pages/Trade'));
const Actions = lazy(() => import('@pages/Actions'));
const Wallet = lazy(() => import('@pages/Wallet'));
const NFT = lazy(() => import ('@pages/NFT'));
const Collections = lazy(() => import('@pages/Collections'));
const SignIn = lazy(() => import('@pages/SignIn'));
const SignUp = lazy(() => import('@pages/SignUp'));
const Verification = lazy(() => import('@pages/Verification'));
const RegistrationModal = lazy(() => import('@widgets/RegistrationModal'));


const App = () => {
    const appRef = useRef(null);
    const {theme} = useThemeProvider();
    const {width} = useWindowSize();

    useEffect(() => {
        appRef.current && appRef.current.scrollTo(0, 0);
    }, []);

    const gaKey = process.env.REACT_APP_PUBLIC_GOOGLE_ANALYTICS;
    gaKey && ReactGA.initialize(gaKey);

    return (
        <SidebarProvider>
            <ThemeProvider theme={{theme: theme}}>
                <ThemeStyles/>
                <ToastContainer theme="colored" autoClose={2000} toastStyle={{borderRadius: 4}}/>
                <ModalProvider>
                    <div ref={appRef}>
                        {width < 768 ? <BottomNav/> : <Sidebar/>}
                        <AuthProvider>
                            <AuthGuard>
                            <RegistrationModal/>
                                <Suspense fallback={<Loader visible/>}>
                                    <Routes>              
                                            <Route path="/" element={<Dashboard/>}/>
                                            <Route path="/wallet" element={<Wallet/>}/>              
                                            <Route path="/sign-in" element={<SignIn/>}/>
                                            <Route path="/sign-up" element={<SignUp/>}/>
                                            <Route path="/trade" element={<Trade/>}/>
                                            <Route path="/actions" element={<Actions/>}/>
                                            <Route path="/nft" element={<NFT/>}/>
                                            <Route path="/collections" element={<Collections/>}/>
                                            <Route path="/verification" element={<Verification/>}/>
                                        
                                    </Routes>

                                </Suspense>
                            </AuthGuard> 
                        </AuthProvider>

                    </div>
                </ModalProvider>
            </ThemeProvider>
        </SidebarProvider>
    );
}

export default App;
