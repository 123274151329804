// styling
import styles from './style.module.scss';

// components
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { NavLink, useNavigate } from 'react-router-dom';
import Switch from '@ui/Switch';
import { useSelector } from 'react-redux'

// hooks
import { useSidebar } from '@contexts/sidebarContext';
import { useWindowSize } from 'react-use';
import { useThemeProvider } from '@contexts/themeContext';
import { useModal } from '@contexts/modalContext';
import useAuthRoute from '@hooks/useAuthRoute';

// utils
import { memo } from 'react';

// assets
import logo from '@assets/logo.svg';
import avatar from '@assets/avatar.webp';

const Sidebar = () => {
    const { open, setOpen } = useSidebar();
    const { modal, handleOpen } = useModal();
    const { theme, setTheme } = useThemeProvider();
    const { dashboard } = useSelector((state) => state.dashboard)
    const isTemporary = useWindowSize().width < 1920;
    const unreadNotifications = dashboard == null ? 0 : dashboard.notifications.length;
    const isActive = (path) => window.location.pathname === path;
    const isAuthRoute = useAuthRoute();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.account)

    const logout = () => {
        localStorage.removeItem('sessionToken'); 
        navigate('/sign-in', { replace: true });
      };

    return (
        <>
            {
                !isAuthRoute ? (
                    <SwipeableDrawer
                        anchor="left"
                        open={isTemporary ? open : true}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        variant={isTemporary ? 'temporary' : 'persistent'}
                        PaperProps={{
                            sx: {
                                width: 310,
                                padding: '32px 32px 40px',
                                backgroundColor: 'var(--widget)',
                                color: theme === 'light' ? 'var(--header)' : 'var(--text)',
                                fontSize: '1rem',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                height: '100%',
                                fontWeight: '600 !important'
                            }
                        }}>
                        <NavLink className={styles.logo} to="/">
                            <img src={logo} alt="logo" style={{ width: 33 }} />
                            <span className="h1">Venture Wave</span>
                        </NavLink>
                        <div className={`${styles.list} flex-1`}>
                            <NavLink className={`nav-link nav-link--sidebar ${styles.list_item}`}
                                key="Dashboard"
                                to="/">
                                <span className="wrapper">
                                    <i className={`icon icon-dashboard ${isActive('/') ? '' : 'active'}`} />
                                    <i className={`icon icon-fill ${isActive('/') ? 'active' : ''} icon-dashboard`} />
                                </span>
                                Dashboard
                            </NavLink>
                            <NavLink className={`nav-link nav-link--sidebar ${styles.list_item}`}
                                key="Wallet"
                                to="/wallet">
                                <span className="wrapper">
                                    <i className={`icon icon-wallet ${isActive('/wallet') ? '' : 'active'}`} />
                                    <i className={`icon icon-fill ${isActive('/wallet') ? 'active' : ''} icon-wallet`} />
                                </span>
                                Wallet
                            </NavLink>

                            {/* <button className={`nav-link nav-link--sidebar ${styles.list_item} ${isActive('/wallet') ? 'active' : ''}`}
                                            onClick={() => navigate('/wallet')}>
                                <span className="wrapper">
                                    <i className={`icon icon-actions ${styles.sm} ${isActive('/wallet') ? 'active' : ''}`}/>
                                    <i className={`icon icon-actions icon-fill ${styles.sm} ${isActive('/wallet') ? 'active' : ''}`}/>
                                </span>
                                Wallet
                            </button> */}

                            <button className={`nav-link nav-link--sidebar ${styles.list_item} ${modal === 'refer' ? 'active' : ''}`}
                                onClick={() => handleOpen('refer')}>
                                <span className="wrapper">
                                    <i className={`icon icon-actions ${styles.sm} ${modal !== 'refer' ? 'active' : ''}`} />
                                    <i className={`icon icon-actions icon-fill ${styles.sm} ${modal === 'refer' ? 'active' : ''}`} />
                                </span>
                                Referrals
                            </button>

                            <button className={`nav-link nav-link--sidebar ${styles.list_item} ${modal === 'account' ? 'active' : ''}`}
                                onClick={() => handleOpen('account')}>
                                <span className="wrapper">
                                    <i className={`icon icon-nft ${styles.sm} ${modal !== 'account' ? 'active' : ''}`} />
                                    <i className={`icon icon-nft icon-fill ${styles.sm} ${modal === 'account' ? 'active' : ''}`} />
                                </span>
                                Account
                            </button>

                        </div>


                        <div className="d-flex flex-column g-40 w-100">
                            <div className={styles.list}>
                                <button className={`nav-link nav-link--sidebar ${styles.list_item} ${modal === 'support' ? 'active' : ''}`}
                                    onClick={() => handleOpen('support')}>
                                    <span className="wrapper">
                                        <i className={`icon icon-headset ${styles.sm} ${modal !== 'support' ? 'active' : ''}`} />
                                        <i className={`icon icon-headset icon-fill ${styles.sm} ${modal === 'support' ? 'active' : ''}`} />
                                    </span>
                                    Support
                                </button>

                                <div className="d-flex justify-content-between align-items-center">
                                    <button className={`nav-link nav-link--sidebar ${styles.list_item} ${modal === 'notifications' ? 'active' : ''}`}
                                        onClick={() => handleOpen('notifications')}>
                                        <span className="wrapper">
                                            <i className={`icon icon-notification ${styles.sm} ${modal !== 'notifications' ? 'active' : ''}`} />
                                            <i className={`icon icon-notification-active icon-fill ${styles.sm} ${modal === 'notifications' ? 'active' : ''}`} />
                                        </span>
                                        Notifications
                                    </button>
                                    <span className="indicator indicator--text">{unreadNotifications}</span>
                                </div>
                                <button className={`nav-link nav-link--sidebar ${styles.list_item} ${modal === 'info' ? 'active' : ''}`}
                                    onClick={() => handleOpen('info')}>
                                    <span className="wrapper">
                                        <i className={`icon icon-info ${modal !== 'info' ? 'active' : ''}`} />
                                        <i className={`icon icon-info-active icon-fill ${modal === 'info' ? 'active' : ''}`} />
                                    </span>
                                    Venture Wave
                                </button>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className={styles.list_item}>
                                        <span>
                                            <i className={`${styles.sm} icon-theme`} />
                                        </span>
                                        Dark Theme
                                    </div>
                                    <Switch id="theme"
                                        checked={theme === 'dark'}
                                        onChange={() => setTheme(theme === 'dark' ? 'light' : 'dark')} />
                                </div>
                                <button className={`nav-link nav-link--sidebar ${styles.list_item}`}
                                    onClick={() => logout()}>
                                    <span className="wrapper">
                                        <i className="icon icon-logout" />
                                    </span>
                                    Log out
                                </button>
                            </div>
                            <div className="d-flex align-items-center g-16 text-header">
                                <img className="avatar" src={avatar} alt="Dan Tourlan" />
                                <button className="text-600" onClick={() => handleOpen('account')}>
                                    {
                                        user != null ? user.name : ""
                                    }
                                </button>
                            </div>
                        </div>
                    </SwipeableDrawer>
                ) : null
            }
        </>
    )

}

export default memo(Sidebar);