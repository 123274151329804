// AuthContext.js
import React, { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux'
import {useNavigate} from 'react-router-dom';
import axios from '@utils/axios';
import {toast} from 'react-toastify';
import {storeUser} from '@features/accountReducer';
import {storeData, storeProducts} from '@features/dashboardReducer';


const AuthContext = createContext();

const setSession = (token) => {
  if (token) {
      localStorage.setItem('sessionToken', token);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
      localStorage.removeItem('sessionToken');
      delete axios.defaults.headers.common.Authorization;
  }
};

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const login = async (email, password) => {
    const response = await axios.post('/api/user-signin', { email, password });
    if(response.data.success == "0"){
      toast.error(response.data.message);
    }else{
      toast.success(response.data.message);
      setauth(response.data)
    }
      
  };

  const register = async (name, phone, email, password, confirm_password, referal_code) => {
    if(password != confirm_password){
      toast.error("Failed. Password does not match!");
      return;
    }else{
      const response = await axios.post('/api/user-signup', { email, password, phone, name, referal_code });
      if(response.data.success == "0"){
        toast.error(response.data.message);
      }else{
        toast.success(response.data.message);
        setauth(response.data)
      }
    }
    
      
  };

  const getUserData = async () => {
    try {
      const response = await axios.get('/api/get-user');
      dispatch(storeData(response.data));
  
    } catch (err) {

    } finally {
    }
      
  };

  const getProducts = async () => {
    try {
      const response = await axios.get('/api/get-products');
      dispatch(storeProducts(response.data));
  
    } catch (err) {

    } finally {
    }
      
  };

  const setauth = (data) => {
    const { token, user,  } = data;
    setSession(token);
    setIsAuthenticated(true);
    dispatch(storeUser(user));
    getUserData();
    // getProducts();
    // navigate('/', { replace: true });
  }
  

  const logout = () => {
    localStorage.removeItem('sessionToken'); 
    setIsAuthenticated(false);
    navigate('/sign-in', { replace: true });
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, setauth, register }}>
      {children}
    </AuthContext.Provider>
  );
};
