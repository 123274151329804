import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoggedIn: false,
    user: null,
    expectedEarnings: 0,
    email: '',
    password: '',
    user_name: '',
    user_email: '',
    user_phone: '',

    bonus_message: '',
    bonus_amount: '',
    daily_earning: '',

    register_name: '',
    register_email: '',
    register_phone: '',
    register_password: '',
    register_referal_code: '',
    register_confirm_password: ''
};

const accountReducer = createSlice({
  name: 'account',
  initialState: initialState,
  reducers: {
    handleInput:(state, action) => {
        const { name, value } = action.payload.target;
        state[name] = value
    },
    storeUser:(state, action) => {
        state.user = action.payload
        state.user_name = state.user.name
        state.user_email = state.user.email
        state.user_phone = state.user.phone
        state.isLoggedIn = true
    },
    storeData:(state, action) => {
      state.bonus_message = action.payload.bonus_message
      state.bonus_amount = action.payload.bonus_amount
      state.daily_earning = action.payload.daily_earning
  },
    storeExpected:(state, action) => {
      state.expectedEarnings = action.payload
    }
  },
})

export const {handleInput, storeUser, storeExpected, storeData,  } = accountReducer.actions

export default accountReducer.reducer