// ProtectedRoute.js
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAuth } from './AuthContext';

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const paths = ['/verification', '/sign-up'];

  useEffect(() => {
      if (!isAuthenticated && !paths.includes(location.pathname)) {
          navigate('sign-in', { replace: true });
      }
  }, [navigate]);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
