/**
 * axios setup to use mock service
 */

import axios from 'axios';

const axiosServices = axios.create(
    {
        baseURL: process.env.REACT_APP_API_BASE_URL,
        timeout: 10000,
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' }
    }
);

// interceptor for http
// axiosServices.interceptors.response.use(
//     (response) => response,
//     (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
// );

export default axiosServices;
